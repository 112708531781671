<template>
  <b-card
    :img-src="getProductImage"
    img-alt="Produto"
    img-top
    class="product-card"
  >
    <div class="product-card-content">
      <h3>{{ getProductDescription }}</h3>

      <div class="d-flex align-items-center flex-wrap">
        <button-form
          button-class="m-2"
          @action="redirectProductDetails"
        >
          {{ getTextButton }}
        </button-form>

        <button-form
          v-if="getSecondButton"
          component-mode="outline"
          button-class="m-2"
          @action="actionSecondButton"
        >
          {{ getTextSecondButton }}
        </button-form>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { productDetailsRouter } from '@/router/routePathName'
import ButtonForm from '@/components/Buttons/ButtonForm.vue'

export default {
  name: 'ProductCard',

  components: {
    BCard,
    ButtonForm,
  },

  props: {
    productId: {
      type: String,
      default: '',
    },
    productUniqueName: {
      type: String,
      default: '',
    },
    productImage: {
      type: String,
      default: '',
    },
    productDescription: {
      type: String,
      default: '',
    },
    textButton: {
      type: String,
      default: 'Visualizar',
    },

    secondButton: {
      type: Boolean,
      default: false,
    },
    textSecondButton: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      productDetailsRouter,
    }
  },

  computed: {
    getProductId() {
      return this.productId
    },
    getProductUniqueName() {
      return this.productUniqueName
    },
    getProductImage() {
      return this.productImage
    },
    getProductDescription() {
      return this.productDescription
    },
    getTextButton() {
      return this.textButton
    },

    getSecondButton() {
      return this.secondButton
    },
    getTextSecondButton() {
      return this.textSecondButton
    },
  },

  methods: {
    redirectProductDetails() {
      this.$router.push({ path: `${productDetailsRouter.path}/${this.getProductUniqueName}` })
    },

    actionSecondButton() {
      this.$emit('actionSecondButton')
    },
  },
}
</script>

<style scoped>
@import './style.scss';
</style>

<template>
  <div>
    <div
      v-if="getCarouselData.length > 0"
      class="carousel-controls"
    >
      <a
        type="button"
        class="control-circle"
        @click="handlePrev"
      >
        <feather-icon
          size="18"
          icon="ChevronLeftIcon"
        />
      </a>

      <a
        type="button"
        class="control-circle"
        @click="handleNext"
      >
        <feather-icon
          size="18"
          icon="ChevronRightIcon"
        />
      </a>
    </div>

    <carousel
      ref="carousel"
      :autoplay="true"
      :loop="true"
      :autoplay-hover-pause="true"
      :pagination-enabled="false"
      :per-page-custom="perPageCustom"
      class="p-0 w-100"
    >
      <slide
        v-for="(item, index) in getCarouselData"
        :key="index"
        class="carousel-slide"
      >
        <product-card
          :product-image="setProductImageUrl(item.image[0].path)"
          :product-description="item.product_description"
          :product-unique-name="item.product_unique_name"
        />
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import ProductCard from '@/components/ProductCard/ProductCard.vue'
import {setProductImageUrl} from "@/utils/helpers/helpers";

export default {
  name: 'CardCarouselSlide',

  components: {
    ProductCard,
    Carousel,
    Slide,
  },

  props: {
    carouselData: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      setProductImageUrl,

      perPageCustom: [
        [1200, 4],
        [992, 3],
        [576, 2],
        [0, 1],
      ],
    }
  },

  computed: {
    getCarouselData() {
      return this.carouselData
    },
  },

  methods: {
    handlePrev() {
      this.$refs.carousel.goToPage(this.$refs.carousel.currentPage - 1)
    },
    handleNext() {
      this.$refs.carousel.goToPage(this.$refs.carousel.currentPage + 1)
    },
  },
}
</script>

<style scoped lang="scss">
.carousel-slide {
  margin-left: 15px;
  margin-right: 9px;
  margin-bottom: 15px;
  max-width: 270px;
}

.carousel-controls {
  display: flex;
  justify-content: flex-end;
}

.carousel-controls .control-circle {
  margin: 25px 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #f19596;
  border-radius: 50%;
  border: 2px solid #f19596;
  text-align: center;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}

.carousel-controls .control-circle:hover {
  background-color: #f19596;
  color: #fff;
}

</style>
